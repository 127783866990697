import * as React from "react"
import { format } from 'date-fns'

import usePartySearch from "@hooks/usePartySearch"

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Loading from "@components/common/Loading"
import Breadcrumbs from "@components/party-search/Breadcrumbs"
import SearchBox from "@components/party-search/SearchBox"
import Main from "@components/party-search/Main"
import Service from "@components/party-search/Service"

const PartySearch = props => {
  const { parties, loading, searchData } = usePartySearch(props.location.search);
  if(loading) return <Loading />
  let seoData = {};
  switch(searchData.type) {
    case 'condition':
      seoData.title = '条件検索 - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place';
      seoData.description = '既婚者合コン・サークルの開催情報の条件検索結果です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。';
      seoData.h1 = '条件検索 - 既婚者合コン一覧';
      break;
    case 'upcoming':
      seoData.title = '近日開催 - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place';
      seoData.description = '既婚者合コン・サークルの近日開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。';
      seoData.h1 = '近日開催 - 既婚者合コン一覧';
      break;
    case 'area':
      seoData.title = `${searchData.area} - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place`;
      seoData.description = `既婚者合コン・サークルの${searchData.area}開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。`;
      seoData.h1 = `${searchData.area} - 既婚者合コン一覧`;
      break;
    case 'date':
      seoData.title = `${format(new Date(searchData.startDate), 'yyyy年M月d日')} - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place`;
      seoData.description = `既婚者合コン・サークルの${format(new Date(searchData.startDate), 'yyyy年M月d日')}開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。`;
      seoData.h1 = `${format(new Date(searchData.startDate), 'yyyy年M月d日')} - 既婚者合コン一覧`;
      break;
    case 'night':
      seoData.title = '夜開催 - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place';
      seoData.description = '既婚者合コン・サークルの夜開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。';
      seoData.h1 = '夜開催 - 既婚者合コン一覧';
      break;
    case 'daytime':
      seoData.title = '昼開催 - 既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place';
      seoData.description = '既婚者合コン・サークルの昼開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。';
      seoData.h1 = '昼開催 - 既婚者合コン一覧';
      break;
    default:
      seoData.title = '既婚者合コン一覧 | 既婚者向け総合メディア - Cuddle Place';
      seoData.description = '既婚者合コン・サークルの開催情報の一覧です。既婚者向け総合メディアCuddle Place（カドル プレイス）で、セカンドパートナー、オープンマリッジ、婚外恋愛など新しい既婚者専用・限定の出会いを探してみましょう。既婚者向けマッチングアプリもおすすめです。';
      seoData.h1 = '既婚者合コン一覧';
  }
  return (
    <Layout>
      <Seo
        title={seoData.title}
        description={seoData.description}
      />
      <Breadcrumbs />
      <SearchBox searchData={searchData} />
      <Main parties={parties} seoData={seoData} />
      <Service />
    </Layout>
  )
}

export default PartySearch
