import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { navigate } from "gatsby"

import Button from "@components/common/Button"

const CuddleplaceSmall = props => {
  return (
    <Container {...props}>
      <StaticImage
        src="../../images/service_title2.png"
        alt="既婚者専用マッチングアプリ"
        width={269}
      />
      <StaticImage
        src="../../images/logo_cuddle.png"
        alt="Cuddle(カドル)ロゴ"
        width={167}
        style={{marginTop: '6px', marginBottom: '16px'}}
      />
      <Button
        variant='contained'
        size='small'
        font='large'
        width='230px'
        onClick={() => navigate('https://www.cuddle-jp.com/?utm_source=cuddle-place&utm_medium=referral&utm_campaign=cuddle-place_normal')}
      >
        使ってみる
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F8F7F1;
  padding: 33px 30px;
`;

export default CuddleplaceSmall
