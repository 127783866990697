import * as React from "react"
import styled from "styled-components"

import Cuddleplace from "@components/common/Cuddleplace"
import PageBack from "@components/common/PageBack"

const Service = () => {
  return (
    <Container>
      <Cuddleplace />
      <PageBack to='/parties/' style={{marginTop: '32px'}}>既婚者合コンに戻る</PageBack>
    </Container>
  )
}

const Container = styled.div`
  padding: 64px 24px;
`;

export default Service
