import React from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh'

import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <Container>
      <CircularProgress style={{display:'block'}} size={50} />
    </Container>
  )
}

const Container = styled(Div100vh)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export default Loading