import * as React from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import queryString from "query-string";
import { addMonths, format } from "date-fns";

import { serviceNameData } from "@enums/index";
import awsmobile from "../aws-exports";
import { partyByAreaByDate } from "@graphql/queries";

Amplify.configure(awsmobile);

const usePartySearch = search => {
  const parsed = queryString.parse(search);
  const [params, setParams] = React.useState(parsed);
  const [loading, setLoading] = React.useState(true);
  const [parties, setParties] = React.useState([]);
  const [sex, setSex] = React.useState("female");
  const [age, setAge] = React.useState(null);
  const [area, setArea] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [serviceName, setServiceName] = React.useState(
    serviceNameData.map(elem => elem.value)
  );
  const searchData = {
    ...params,
    sex: sex,
    setSex: setSex,
    age: age,
    setAge: setAge,
    area: area,
    setArea: setArea,
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    startTime: startTime,
    setStartTime: setStartTime,
    endTime: endTime,
    setEndTime: setEndTime,
    serviceName: serviceName,
    setServiceName: setServiceName,
    setParties: setParties,
    setLoading: setLoading,
    setParams: setParams
  };
  React.useEffect(() => {
    if (!loading) return false;
    (async () => {
      let filter = {};
      const areaData = params.area ? params.area : "東京";
      setArea(areaData);
      const startDateData = params.startDate
        ? params.startDate
        : format(new Date(), "yyyy-MM-dd");
      setStartDate(startDateData);
      const endDateData = params.endDate
        ? params.endDate
        : format(addMonths(new Date(), 1), "yyyy-MM-dd");
      setEndDate(endDateData);
      if (params.age) {
        filter.maleAgeFrom = { le: Number(params.age) };
        filter.maleAgeEnd = { ge: Number(params.age) };
        setAge(params.age);
      }
      if (params.femaleVacancy) {
        filter.femaleVacancy = { eq: params.femaleVacancy };
        setSex("female");
      }
      if (params.maleVacancy) {
        filter.maleVacancy = { eq: params.maleVacancy };
        setSex("male");
      }
      if (params.startTime) {
        filter.startAt = { ge: `${params.startTime}.00.000` };
        setStartTime(params.startTime);
      }
      filter.endAt = { ne: "" };
      if (params.endTime) {
        filter.and = { endAt: { lt: `${params.endTime}.00.000` } };
        setEndTime(params.endTime);
      }
      if (params.serviceName) {
        filter.or = params.serviceName
          .split(",")
          .map(elem => ({ serviceName: { eq: elem } }));
        setServiceName(params.serviceName.split(","));
      }
      const res = await API.graphql(
        graphqlOperation(partyByAreaByDate, {
          area: areaData,
          date: { between: [startDateData, endDateData] },
          filter: filter,
          limit: 1000
        })
      );
      const items = res.data.partyByAreaByDate.items
        .sort((a, b) => {
          if (a.startAt < b.startAt) return -1;
          if (a.startAt > b.startAt) return 1;
          return 0;
        })
        .sort((a, b) => {
          if (a.date < b.date) return -1;
          if (a.date > b.date) return 1;
          return 0;
        });
      setParties(items);
      setLoading(false);
    })();
  }, [loading, params]);
  return { parties, loading, searchData };
};

export default usePartySearch;
