import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import queryString from 'query-string'

import SearchDetail from "@components/common/SeachDetail"
import Button from "@components/common/Button"

const SearchBox = props => {
  const { searchData } = props;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const clickSearch = () => {
    const parsed = queryString.parse();
    searchData.type = 'condition';
    searchData.sex === 'male'?
      parsed.maleVacancy = 'true':
      parsed.femaleVacancy = 'true';
    if(searchData.age) parsed.age = searchData.age;
    if(searchData.area) parsed.area = searchData.area;
    if(searchData.startDate) parsed.startDate = searchData.startDate;
    if(searchData.endDate) parsed.endDate = searchData.endDate;
    if(searchData.startTime) parsed.startTime = searchData.startTime;
    if(searchData.endTime) parsed.endTime = searchData.endTime;
    if(searchData.serviceName.length > 0) parsed.serviceName = searchData.serviceName.join(',');
    const stringified = queryString.stringify(parsed);
    navigate(`/party-search/?${stringified}`);
    searchData.setLoading(true);
    searchData.setParties([]);
    searchData.setParams(parsed);
  }
  return (
    <Container>
      <Button
        variant='contained'
        size='small'
        font='medium'
        width='75%'
        icon={true}
        onClick={() => setOpenDrawer(true)}
      >
        条件を変更する
      </Button>
      <SearchDetail openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} data={searchData} clickSearch={clickSearch}  />
    </Container>
  )
}

const Container = styled.div`
  padding: 33px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F8F7F1;
  margin-bottom: 45px;
`;

export default SearchBox
