import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { format } from 'date-fns'
import ja from "date-fns/locale/ja"

import CuddleplaceSmall from  "@components/common/CuddleplaceSmall"
import Card from "@components/party-search/Card"

import Pagination from '@mui/material/Pagination';

const sliceByNumber = (array, number) => {
  const length = Math.ceil(array.length / number)
  return new Array(length).fill().map((_, i) =>
    array.slice(i * number, (i + 1) * number)
  )
}

const Main = props => {
  const [page, setPage] = React.useState(1);
  const { parties, seoData } = props;
  const items = parties.map(elem => ({
    id: elem.id,
    title: elem.title,
    area: elem.area,
    startAt: `${format(new Date(elem.date), 'M/d(E)', { locale: ja })} ${elem.startAt.split(':')[0]}:${elem.startAt.split(':')[1]}〜${elem.endAt.split(':')[0]}:${elem.endAt.split(':')[1]}`,
    src: `${elem.id.split('_')[0]}`,
    access: elem.access,
    maleVacancy: elem.maleVacancy,
    maleAgeFrom: elem.maleAgeFrom,
    maleAgeEnd: elem.maleAgeEnd,
    maleFeeMin: elem.maleFeeMin,
    femaleVacancy: elem.femaleVacancy,
    femaleAgeFrom: elem.femaleAgeFrom,
    femaleAgeEnd: elem.femaleAgeEnd,
    femaleFeeMin: elem.femaleFeeMin
  }));
  const sliceItems = sliceByNumber(items, 10);
  const list = sliceItems[page - 1];
  const clickPagination = (value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
    });
  }
  return (
    <Container>
      <Title>{seoData.h1}</Title>
      <ResultCount>検索結果 {items.length}件</ResultCount>
      {items.length > 0 &&
        <React.Fragment>
          {list.slice(0,5).map((elem) => (
            <Card
              {...elem}
              onClick={() => navigate(`/party/${elem.id}`)}
              key={elem.id}
            />
          ))}
          <CuddleplaceSmall style={{marginBottom: '30px'}} />
          {list.slice(5,10).map((elem) => (
            <Card
              {...elem}
              onClick={() => navigate(`/party/${elem.id}`)}
              key={elem.id}
            />
          ))}
          <PagenationContainer>
            <Pagination
              count={sliceItems.length}
              page={page}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={(e, value) => clickPagination(value)}
            />
          </PagenationContainer>
        </React.Fragment>
      }
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 24px;
`;

const Title = styled.h1`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
`;

const ResultCount = styled.h2`
  margin-bottom: 16px;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
`;

const PagenationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 20px;
`;

export default Main
