import * as React from "react"
import styled from "styled-components"

import PartyImage from "@components/common/PartyImage"

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Card = props => {
  const {
    title,
    area,
    startAt,
    src,
    access,
    maleVacancy,
    maleAgeFrom,
    maleAgeEnd,
    maleFeeMin,
    femaleVacancy,
    femaleAgeFrom,
    femaleAgeEnd,
    femaleFeeMin
  } = props;
  return (
    <Container {...props}>
      <ImageWrapper>
        {src.includes('http')?
          <Image
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
          />:
          <PartyImage
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
            ImageStyle={ImageStyle}
          />
        }
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Info>
          {startAt &&
            <StartAt>
              <CalendarMonthRoundedIcon sx={IconStyle} />
              <Text>{startAt}</Text>
            </StartAt>
          }
          {area &&
            <Location>
              <LocationOnOutlinedIcon sx={IconStyle} />
              <Text style={{letterSpacing: '0.1em'}}>{area}{access && ` | ${access}`}</Text>
            </Location>
          }
        </Info>
        <Detail>
          <DetailCard sex="male">
            <Sex sex="male">男性</Sex>
            <DetailCardInfo>
              <Vacancy>{maleVacancy? '空席あり':'満員'}</Vacancy>
              <Age>{`${maleAgeFrom}〜${maleAgeEnd}歳`}</Age>
              <Fee>{`${maleFeeMin}円〜`}</Fee>
            </DetailCardInfo>
          </DetailCard>
          <DetailCard sex="female">
            <Sex sex="female">女性</Sex>
            <DetailCardInfo>
              <Vacancy>{femaleVacancy? '空席あり':'満員'}</Vacancy>
              <Age>{`${femaleAgeFrom}〜${femaleAgeEnd}歳`}</Age>
              <Fee>{`${femaleFeeMin}円〜`}</Fee>
            </DetailCardInfo>
          </DetailCard>
        </Detail>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  border: 1px solid #C4CDD5;
  border-radius: 8px;
  margin-bottom: 30px;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const ImageStyle = {
  'borderRadius': '8px 8px 0px 0px',
  'width': '100%'
}

const Image = styled.img`
  border-radius: 8px 8px 0px 0px;
`;

const Content = styled.div`
  padding: 15px;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const Info = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyle = {
  fontSize: '16px',
  color: '#999999',
  marginRight: '6px'
}

const StartAt = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0em;
  color: #999999;
  witdh: 100%;
  word-wrap: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailCard = styled.div`
  width: 47.5%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: ${props => props.sex === 'male'? '1px solid #7CC2C4':'1px solid #F39381'};
  padding: 15px;
`;

const Sex = styled.span`
  color: ${props => props.sex === 'male'? '#7CC2C4':'#F39381'};
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1em;
`;

const DetailCardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 12px;
`;

const Vacancy = styled.span`
  font-size: 14px;
  line-height: 1;
  color: #333333;
  margin-bottom: 8px;
`;

const Age = styled.span`
  font-size: 11px;
  line-height: 1;
  color: #666666;
  margin-bottom: 8px;
`;

const Fee = styled.span`
  font-size: 11px;
  line-height: 1;
  color: #666666;
`;

export default Card
